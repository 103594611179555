import deepEqual from 'fast-deep-equal'
import Channel from './Channel'

const defaultChannelOpts: IChannelOptions = {
  disableFlush: false,
  isSequence: false,
}

class EventBus {
  private readonly channels: any = {}

  channel(channelName: string, options: IChannelOptions = defaultChannelOpts) {
    if (!this.channels[channelName]) {
      this.channels[channelName] = new Channel(options)
    }

    if (!deepEqual(this.channels[channelName].options, options)) {
      console.warn('The specified options do not match with the existing instace of channel.')
    }

    return this.channels[channelName]
  }
}

export default new EventBus()
